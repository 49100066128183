<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Your Health</h3>

    <HealthYN v-model="form.clientData.health_status"/>
    <transition name="fade">
      <div class="question-box with-arrow" v-if="healthIssues(form.clientData)">
        <HealthDetails v-model="form.clientData.health_issues"/>
      </div>
    </transition>

    <CapacityYN v-model="form.clientData.capacity_status"/>
    <transition name="fade">
      <div class="question-box with-arrow" v-if="capacityIssues(form.clientData)">
        <CapacityDetails v-model="form.clientData.capacity_issues"/>
      </div>
    </transition>

    <HearingSightYN v-model="form.clientData.hearing_sight_impairment_status"/>
    <transition name="fade">
      <div class="question-box with-arrow" v-if="hearingSightImpairmentStatusDetails(form.clientData)">
        <HearingSightDetails v-model="form.clientData.hearing_sight_impairment_status_details"/>
      </div>
    </transition>

    <MobilityYN v-model="form.clientData.mobility_status"/>
    <transition name="fade">
      <div class="question-box with-arrow" v-if="mobilityIssues(form.clientData)">
        <MobilityDetails v-model="form.clientData.mobility_issues"/>
      </div>
    </transition>

    <LanguageYN v-model="form.clientData.language_status"/>
    <transition name="fade">
      <div class="question-box with-arrow" v-if="languageIssues(form.clientData)">
        <LanguageDetails v-model="form.clientData.language_issues"/>
      </div>
    </transition>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import HealthYN from "../../question/questions/estatePlanningSurvey/health/HealthYN";
import HealthDetails from "../../question/questions/estatePlanningSurvey/health/HealthDetails";
import CapacityYN from "../../question/questions/estatePlanningSurvey/health/CapacityYN";
import CapacityDetails from "../../question/questions/estatePlanningSurvey/health/CapacityDetails";
import HearingSightYN from "../../question/questions/estatePlanningSurvey/health/HearingSightYN";
import HearingSightDetails from "../../question/questions/estatePlanningSurvey/health/HearingSightDetails";
import MobilityYN from "../../question/questions/estatePlanningSurvey/health/MobilityYN";
import MobilityDetails from "../../question/questions/estatePlanningSurvey/health/MobilityDetails";
import LanguageYN from "../../question/questions/estatePlanningSurvey/health/LanguageYN";
import LanguageDetails from "../../question/questions/estatePlanningSurvey/health/LanguageDetails";

export default {
  name: 'HealthStatus',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    LanguageDetails,
    LanguageYN,
    MobilityDetails,
    MobilityYN,
    HearingSightDetails,
    HearingSightYN,
    CapacityDetails,
    CapacityYN,
    HealthYN,
    HealthDetails
  },
  data() {
    return {
      label: 'Health' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
