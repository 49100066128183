<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputTextArea from "../../../inputs/InputTextArea";

export default {
  name: 'HearingSightDetails',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'How exactly is your hearing or sight impaired?',
        subTitle:  'If such an impairment makes it difficult to talk on the phone or read documents, the estate planning process may need to be adjusted. In certain situations this may have an impact on the cost of any services recommended.',
        tip: null
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'hearing_sight_impairment_status_details',
        formPath: 'clientData.hearing_sight_impairment_status_details'
      },
      args: {
        placeholder: 'e.g. broken heart',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>