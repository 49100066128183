<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";
import { showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'WillOtherAssetsPartnerYN',
  mixins: [general, questionLoadHelpers, showClientQuestionHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `Are you intending to leave all ${ this.willDistributionResidentialPartner(this.form) ? 'other' : 'your'} assets to your partner initially?`,
        subTitle: null,
        tip: null,
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_distribution_other_assets_to_partner',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
