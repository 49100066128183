<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Business</h3>

    <BusinessContinueYN
        v-if="businessContinue(form)"
        v-model="form.business_continue" />
    <BusinessOtherShareholdersYN
        v-if="businessOtherShareHolders(form)"
        v-model="form.business_other_shareholders" />
    <BusinessShareholdersAgreementYN
        v-if="businessShareHoldersAgreement(form)"
        v-model="form.business_shareholders_agreement"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";
import BusinessContinueYN from "../../question/questions/estatePlanningSurvey/business/BusinessContinueYN";
import BusinessOtherShareholdersYN
  from "../../question/questions/estatePlanningSurvey/business/BusinessOtherShareholdersYN";
import BusinessShareholdersAgreementYN
  from "../../question/questions/estatePlanningSurvey/business/BusinessShareholdersAgreementYN";


export default {
  name: 'Business',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    BusinessShareholdersAgreementYN,
    BusinessOtherShareholdersYN,
    BusinessContinueYN

  },
  data() {
    return {
      label: 'Business', // used for steps progress
      loadCondition: this.showFieldset // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
    }
  },
  methods: {
    showFieldset() {
        return this.businessContinue(this.form)
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
