<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'CapacityYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Do you have any medical issues which may give rise to any concerns about your capacity (dementia for example)?',
        subTitle:  'There are varying levels of capacity, where capacity partially impaired or varies from time to time a formal capacity assessment may be required. Where capacity is severely impaired it may not be possible to proceed with a particular service or undertaking.',
        tip: null,
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'capacity_status',
        formPath: 'clientData.capacity_status'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>