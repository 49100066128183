<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";
import { general } from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";

export default {
  name: 'LifeInsuranceProfessionallyArrangedYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Were your insurance policies organised by an independent insurance professional?',
        subTitle:  'This would include a financial advisor or insurance broker but not a typical bank or mortgage advisor.',
        tip: null,
      },
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'life_insurance_professionally_arranged'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>