<template>
  <div v-if="form"
       class="fieldset">
    <h3 class="fieldset-heading">Distribution</h3>

    <WillResidentialPartnerYN v-if="willDistributionResidentialPartner(form)"
                              v-model="form.will_distribution_residential_partner"
                              :clientData="form.clientData"/>

    <WillResidentialChildrenAfterYN
        v-if="willDistributionResidentialChildrenAfter(form)"
        v-model="form.will_distribution_residential_children_after"
        :clientData="form.clientData"
        :toPartnerFirst="form.will_distribution_residential_partner"
    />

    <transition name="fade">
      <div
          v-if="willDistributionPartnerResideDetails(form) || willDistributionTenantsInCommon(form) || willDistributionEstateToDetails(form)"
          class="question-box">
        <WillPartnerResidesDetails v-if="willDistributionPartnerResideDetails(form)"
                                   v-model="form.will_distribution_partner_reside_details"/>
        <WillTenantsInCommonYN v-if="willDistributionTenantsInCommon(form)"
                               v-model="form.will_distribution_tenants_in_common"/>

        <WillDistributionDetails v-if="willDistributionEstateToDetails(form)"
                                 v-model="form.will_distribution_estate_to_details"/>
      </div>
    </transition>


    <WillOtherAssetsPartnerYN v-if="willDistributionOtherAssetsToPartner(form)"
                              v-model="form.will_distribution_other_assets_to_partner"
                              :form="form"/>


    <WillAllChildrenBeneficiariesYN v-if="willDistributionAllChildrenBeneficiaries(form)"
                                    v-model="form.will_distribution_all_children_beneficiaries"
                                    :client-data="form.clientData"/>


    <transition name="fade">
      <div
          v-if="willDistributionChildrenExcludedDetails(form) || willDistributionOtherAssetsDetails(form)"
          class="question-box">
        <WillChildrenExcludedDetails v-if="willDistributionChildrenExcludedDetails(form)"
                                     v-model="form.will_distribution_children_excluded_details"/>

        <WillOtherAssetsDetails v-if="willDistributionOtherAssetsDetails(form)"
                                v-model="form.will_distribution_other_assets_details"
                                :form="form"/>
      </div>
    </transition>


    <WillOtherPropertiesOccupiedFriendsYN v-if="willDistributionOtherPropertiesOccupiedFriends(form)"
                                          :client-data="form.clientData"
                                          v-model="form.will_distribution_other_properties_occupied_friends"/>
    <transition name="fade">
      <div v-if="willDistributionOtherPropertiesOccupiedFriendsDetails(form)"
           class="question-box with-arrow">
        <WillOtherPropertiesOccupiedFriendsDetails v-if="willDistributionOtherPropertiesOccupiedFriendsDetails(form)"
                                                   v-model="form.will_distribution_other_properties_occupied_friends_details"/>
        <WillOtherPropertiesFriendsContinueYN v-if="willDistributionOtherPropertiesOccupiedFriendsContinue(form)"
                                              v-model="form.will_distribution_other_properties_occupied_friends_continue"/>
      </div>
    </transition>


    <hr>

    <WillBeneficiariesIllnessWorkingYN v-if="willDistributionBeneficiariesIllnessWorking(form)"
                                       v-model="form.will_distribution_beneficiaries_illness_working"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesIllnessWorkingPeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesIllnessWorkingPeople v-model="form.will_distribution_beneficiaries_illness_working_people"/>
        <WillBeneficiariesIllnessWorkingDetails v-if="willDistributionBeneficiariesIllnessWorkingDetails(form)"
                                                v-model="form.will_distribution_beneficiaries_illness_working_details"/>
      </div>
    </transition>

    <WillBeneficiariesManagingFinancesYN v-if="willDistributionBeneficiariesManagingFinances(form)"
                                         v-model="form.will_distribution_beneficiaries_managing_finances"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesManagingFinancesPeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesManagingFinancesPeople
            v-model="form.will_distribution_beneficiaries_managing_finances_people"/>
        <WillBeneficiariesManagingFinancesDetails v-if="willDistributionBeneficiariesManagingFinancesDetails(form)"
                                                  v-model="form.will_distribution_beneficiaries_managing_finances_details"/>
      </div>
    </transition>

    <WillBeneficiariesFinancialVulnerableYN v-if="willDistributionBeneficiariesFinancialVulnerable"
                                            v-model="form.will_distribution_beneficiaries_financial_vulnerable"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesFinancialVulnerablePeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesFinancialVulnerablePeople
            v-model="form.will_distribution_beneficiaries_financial_vulnerable_people"/>
        <WillBeneficiariesFinancialVulnerableDetails
            v-if="willDistributionBeneficiariesFinancialVulnerableDetails(form)"
            v-model="form.will_distribution_beneficiaries_financial_vulnerable_details"/>
      </div>
    </transition>

    <WillBeneficiariesStateBenefitsYN v-if="willDistributionBeneficiariesStateBenefits(form)"
                                      v-model="form.will_distribution_beneficiaries_state_benefits"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesStateBenefitsPeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesStateBenefitsPeople v-model="form.will_distribution_beneficiaries_state_benefits_people"/>
        <WillBeneficiariesStateBenefitsDetails v-if="willDistributionBeneficiariesStateBenefitsDetails(form)"
                                               v-model="form.will_distribution_beneficiaries_state_benefits_details"/>
      </div>
    </transition>

    <WillBeneficiariesDrugsYN v-if="willDistributionBeneficiariesDrugs(form)"
                              v-model="form.will_distribution_beneficiaries_drugs"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesDrugsPeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesDrugsPeople v-model="form.will_distribution_beneficiaries_drugs_people"/>
        <WillBeneficiariesDrugsDetails v-if="willDistributionBeneficiariesDrugsDetails(form)"
                                       v-model="form.will_distribution_beneficiaries_drugs_details"/>
      </div>
    </transition>

    <WillBeneficiariesDivorcedYN v-if="willDistributionBeneficiariesDivorced(form)"
                                 v-model="form.will_distribution_beneficiaries_divorced"/>
    <transition name="fade">
      <div v-if="willDistributionBeneficiariesDivorcedPeople(form)"
           class="question-box with-arrow">
        <WillBeneficiariesDivorcedPeople v-model="form.will_distribution_beneficiaries_divorced_people"/>
        <WillBeneficiariesDivorcedDetails v-if="willDistributionBeneficiariesDivorcedDetails(form)"
                                          v-model="form.will_distribution_beneficiaries_divorced_details"/>
      </div>
    </transition>

    <!--    <WillEstateTaxableYN-->
    <!--        v-if="willDistributionEstateTaxable(form)"-->
    <!--        v-model="form.will_distribution_estate_taxable"/>-->
    <WillPensionCollectingYN v-if="willDistributionPensionCollecting(form)"
                             v-model="form.will_distribution_pension_collecting"/>
    <WillPensionNominateDetails v-if="willDistributionPensionNominationDetails(form)"
                                v-model="form.will_distribution_pension_nomination_details"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {
  showClientQuestionHelpers
} from "../../../../../views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";
import WillResidentialPartnerYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillResidentialPartnerYN";
import WillTenantsInCommonYN from "../../question/questions/estatePlanningSurvey/distribution/WillTenantsInCommonYN";
import WillPartnerResidesDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillPartnerResidesDetails";
import WillResidentialChildrenAfterYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillResidentialChildrenAfterYN";
import WillDistributionDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillDistributionDetails";
import WillOtherAssetsPartnerYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillOtherAssetsPartnerYN";
import WillOtherAssetsDetails from "../../question/questions/estatePlanningSurvey/distribution/WillOtherAssetsDetails";
import WillAllChildrenBeneficiariesYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillAllChildrenBeneficiariesYN";
import WillChildrenExcludedDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillChildrenExcludedDetails";
import WillOtherPropertiesOccupiedFriendsYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillOtherPropertiesOccupiedFriendsYN";
import WillOtherPropertiesFriendsContinueYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillOtherPropertiesFriendsContinueYN";
import WillBeneficiariesIllnessWorkingYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesIllnessWorkingYN";
import WillBeneficiariesIllnessWorkingDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesIllnessWorkingDetails";
import WillBeneficiariesManagingFinancesYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiairiesManagingFinancesYN";
import WillBeneficiariesManagingFinancesDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesManagingFinancesDetails";
import WillBeneficiariesFinancialVulnerableYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesFinancialVulnerableYN";
import WillBeneficiariesFinancialVulnerableDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillbeneficairiesFinancialVulnerableDetails";
import WillBeneficiariesStateBenefitsYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesStateBenefitsYN";
import WillBeneficiariesStateBenefitsDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesStateBenefitsDetails";
import WillBeneficiariesDrugsYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiairesDrugsYN";
import WillBeneficiariesDrugsDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesDrugsDetails";
import WillBeneficiariesDivorcedYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesDivorcedYN";
import WillBeneficiariesDivorcedDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesDivorcedDetails";
// import WillEstateTaxableYN from "../../question/questions/estatePlanningSurvey/distribution/WillEstateTaxableYN";
import WillPensionCollectingYN
  from "../../question/questions/estatePlanningSurvey/distribution/WillPensionCollectingYN";
import WillPensionNominateDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillPensionNominateDetails";
import WillOtherPropertiesOccupiedFriendsDetails
  from "../../question/questions/estatePlanningSurvey/distribution/WillOtherPropertiesOccupiedFriendsDetails";
import WillBeneficiariesIllnessWorkingPeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesIllnessWorkingPeople";
import WillBeneficiariesManagingFinancesPeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesManagingFinancesPeople";
import WillBeneficiariesFinancialVulnerablePeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesFinancialVulnerablePeople";
import WillBeneficiariesStateBenefitsPeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesStateBenefitsPeople";
import WillBeneficiariesDrugsPeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesDrugsPeople";
import WillBeneficiariesDivorcedPeople
  from "../../question/questions/estatePlanningSurvey/distribution/WillBeneficiariesDivorcedPeople";


export default {
  name: 'Distribution',
  mixins: [
    fieldsetHelpers,
    showClientQuestionHelpers,
    dateFormat
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    WillBeneficiariesDivorcedPeople,
    WillBeneficiariesDrugsPeople,
    WillBeneficiariesStateBenefitsPeople,
    WillBeneficiariesFinancialVulnerablePeople,
    WillBeneficiariesManagingFinancesPeople,
    WillBeneficiariesIllnessWorkingPeople,
    WillOtherPropertiesOccupiedFriendsDetails,
    WillPensionNominateDetails,
    WillPensionCollectingYN, // WillEstateTaxableYN,
    WillBeneficiariesDivorcedDetails,
    WillBeneficiariesDivorcedYN,
    WillBeneficiariesDrugsDetails,
    WillBeneficiariesDrugsYN,
    WillBeneficiariesStateBenefitsDetails,
    WillBeneficiariesStateBenefitsYN,
    WillBeneficiariesFinancialVulnerableDetails,
    WillBeneficiariesFinancialVulnerableYN,
    WillBeneficiariesManagingFinancesDetails,
    WillBeneficiariesManagingFinancesYN,
    WillBeneficiariesIllnessWorkingDetails,
    WillBeneficiariesIllnessWorkingYN,
    WillOtherPropertiesFriendsContinueYN,
    WillOtherPropertiesOccupiedFriendsYN,
    WillChildrenExcludedDetails,
    WillAllChildrenBeneficiariesYN,
    WillOtherAssetsDetails,
    WillOtherAssetsPartnerYN,
    WillDistributionDetails,
    WillResidentialChildrenAfterYN,
    WillPartnerResidesDetails,
    WillTenantsInCommonYN,
    WillResidentialPartnerYN

  },
  data() {
    return {
      label: 'Distribution' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
