<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";
import { general } from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";

export default {
  name: 'LifeInsuranceAllInTrustYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Have all your life policies been written in trust?',
        subTitle: `Where a trust has been drafted for insurance policies any pay-out is made directly to named beneficiaries (instead of to your estate), this will enable policy proceeds to be paid our much faster, also Inheritance Tax may be reduced or eliminated entirely.`,
        tip: null,
      },
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'life_insurance_all_in_trust'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>