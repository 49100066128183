<template>
  <div class="fieldset">
    <template v-if="separated(form)">
      <h3 class="fieldset-heading">Previous Relationship</h3>
      <SeparationDivorceProceedingStartedYN
          v-if="separationDivorceProceedingsStarted(form)"
          v-model="form.separation_divorce_proceedings_started"/>
      <SeparationAgreementSignedYN
          v-if="separationAgreementSigned(form)"
          v-model="form.separation_agreement_signed"/>
      <SeparationPersonName
          v-if="separationPersonName(form)"
          v-model="form.clientData.profileRelationshipPreviousPersonName"
      />
    </template>

    <template v-if="divorced(form)">
      <h3 class="fieldset-heading">Previous Relationship</h3>
      <DivorceSpouceRemarriedYN
          v-if="divorceSpouseRemarried(form)"
          v-model="form.divorce_spouse_remarried"/>
      <DivorceConsentOrderSignedYN
          v-if="divorceConsentOrderSigned(form)"
          v-model="form.divorce_consent_order_signed"/>
      <DivorcePersonName
          v-if="divorcePersonName(form)"
          v-model="form.clientData.profileRelationshipPreviousPersonName"
      />
    </template>

    <template v-if="widowed(form)">
      <h3 class="fieldset-heading">Previous Relationship</h3>
      <WidowedYearOfDeath
          v-if="widowedYearOfDeath(form)"
          v-model="form.widowed_year_of_death"
      />
      <WidowedDistributionToOtherYN
          v-if="widowedDistributionToOther(form)"
          v-model="form.widowed_distribution_to_other"
      />
      <transition name="fade">
        <div class="question-box with-arrow" v-if="widowedDistributionToOtherDetails(form)">
          <WidowedDistributionToOtherDetails
              v-model="form.widowed_distribution_to_other_details"
          />
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {
  showClientQuestionHelpers
} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";
import DivorceConsentOrderSignedYN
  from "../../question/questions/estatePlanningSurvey/divorce/DivorceConsentOrderSignedYN";
import SeparationDivorceProceedingStartedYN
  from "../../question/questions/estatePlanningSurvey/divorce/SeparationDivorceProceedingStartedYN";
import DivorceSpouceRemarriedYN from "../../question/questions/estatePlanningSurvey/divorce/DivorceSpouceRemarriedYN";
import SeparationAgreementSignedYN
  from "../../question/questions/estatePlanningSurvey/divorce/SeparationAgreementSignedYN";
import DivorcePersonName
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/divorce/DivorcePersonName";
import SeparationPersonName
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/divorce/SeparationPersonName";
import WidowedYearOfDeath
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/divorce/WidowedYearOfDeath";
import WidowedDistributionToOtherYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/divorce/WidowedDistributionToOtherYN";
import WidowedDistributionToOtherDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/divorce/WidowedDistributionToOtherDetails";

export default {
  name: 'Divorce',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    WidowedDistributionToOtherDetails,
    WidowedDistributionToOtherYN,
    WidowedYearOfDeath,
    SeparationPersonName,
    DivorcePersonName,
    SeparationAgreementSignedYN,
    DivorceSpouceRemarriedYN,
    SeparationDivorceProceedingStartedYN,
    DivorceConsentOrderSignedYN

  },
  data() {
    return {
      label: 'Previous Relationship', // used for steps progress
      loadCondition: this.showFieldset // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
    }
  },
  methods: {
    showFieldset() {
      return this.divorced(this.form) || this.separated(this.form) || this.widowed(this.form)
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
