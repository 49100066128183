<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data && !!data.length">
    <InputSelect
        v-model="data"
        :options="people"
        :db="db"
        :args="args"
        :skipped="skipped"
        :js-errors="jsErrors"
        :multiple="true"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputSelect from "../../../inputs/InputSelect";

export default {
  name: 'WillBeneficiariesDivorcedPeople',
  mixins: [questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: Array,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    people () {
      return this.$store.getters.people.map(person => {
        return {
          value: person.id,
          label: `${person.name_first} ${person.name_last}`
        }
      })
    }
  },
  data () {
    return {
      question: {
        title:  'Is the person that this applies to listed below?',
        subTitle:  'Please select multiple where applicable. If the person or people are are not listed add their details in the following question.',
        tip: null,
      },
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_distribution_beneficiaries_divorced_people'
      },
      args: {
        taggable: false,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: false,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>