<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Policies</h3>
    <LifeInsuranceAllInTrustYN v-if="lifeInsuranceAllInTrust(form)"
                               v-model="form.life_insurance_all_in_trust" />
    <LifeInsuranceProfessionallyArrangedYN v-if="lifeInsuranceProfessionallyArranged(form)"
                                           v-model="form.life_insurance_professionally_arranged" />
    <LifeInsuranceArrangedBy v-if="lifeInsuranceArrangedDetails(form)"
                             v-model="form.life_insurance_arranged_details" />
    <LifeInsuranceRecentlyReviewedYN v-if="lifeInsuranceReviewedRecently(form)"
                                     v-model="form.life_insurance_reviewed_recently" />
    <LifeInsuranceConfirmNoneYN v-if="lifeInsuranceNone(form)"
                                v-model="form.life_insurance_none" />
    <h3 v-if="financialAdvisor(form)"
        class="fieldset-heading">Financial Advisor</h3>
    <FinancialAdvisorYN v-if="financialAdvisor(form)"
                        v-model="form.financial_advisor" />
    <h3 v-if="mortgageEarlyRepaymentCharge(form)"
        class="fieldset-heading">Mortgage</h3>
    <MortgageEarlyRepaymentChargeYN v-if="mortgageEarlyRepaymentCharge(form)"
                                    v-model="form.mortgage_early_repayment_charge" />
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import LifeInsuranceAllInTrustYN from "../../question/questions/estatePlanningSurvey/existingProducts/insurance/LifeInsuranceAllInTrustYN";
import LifeInsuranceArrangedBy from "../../question/questions/estatePlanningSurvey/existingProducts/insurance/LifeinsuranceArrangedBy";
import LifeInsuranceProfessionallyArrangedYN from "../../question/questions/estatePlanningSurvey/existingProducts/insurance/LifeInsuranceProfessionallyArrangedYN";
import LifeInsuranceRecentlyReviewedYN from "../../question/questions/estatePlanningSurvey/existingProducts/insurance/LifeInsuranceRecentlyReviewedYN";
import LifeInsuranceConfirmNoneYN from "../../question/questions/estatePlanningSurvey/existingProducts/insurance/LifeInsuranceConfirmNoneYN";
import MortgageEarlyRepaymentChargeYN from "../../question/questions/estatePlanningSurvey/existingProducts/mortgage/MortgageEarlyRepaymentChargeYN";
import FinancialAdvisorYN from "../../question/questions/estatePlanningSurvey/existingProducts/mortgage/FinancialAdvisorYN";


export default {
  name: 'Insurance',
  mixins: [
    fieldsetHelpers,
    showClientQuestionHelpers,
    dateFormat
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    FinancialAdvisorYN,
    MortgageEarlyRepaymentChargeYN,
    LifeInsuranceConfirmNoneYN,
    LifeInsuranceRecentlyReviewedYN,
    LifeInsuranceProfessionallyArrangedYN,
    LifeInsuranceArrangedBy,
    LifeInsuranceAllInTrustYN
  },
  data() {
    return {
      label: 'Financial' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
