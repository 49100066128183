<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputTextArea from "../../../inputs/InputTextArea";

export default {
  name: 'WillPartnerResidesDetails',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Where would your partner live if you passed away?',
        subTitle:  'We ask this question to assess the likelihood af any potential claims against your estate.',
        tip: null
      },
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_distribution_partner_reside_details'
      },
      args: {
        placeholder: 'e.g. My partner owns a separate property where they could live.',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>