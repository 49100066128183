<template>
  <QuestionnaireBase :form="form" :title="title" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import HealthStatus from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/HealthStatus";
import ExistingProducts
  from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/ExistingProducts";
import Business from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/Business";
import Distribution from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/Distribution";
import Insurance from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/Insurance";
import Divorce from "../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/Divorce";

export default {
  name: "EstatePlanningSurvey",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    HealthStatus,
    ExistingProducts,
    Insurance,
    Business,
    Distribution,
    Divorce
  },
  data() {
    return {
      name: 'EstatePlanningSurvey',
      title: 'Estate Planning Survey',
      url: 'estate_planning_survey',
      order:
          ['HealthStatus',
            'Divorce',
            'ExistingProducts',
            'Business',
            'Distribution',
            'Insurance'
          ],
      expiryAccessCodeOnFinish: true

    }
  }
};
</script>
