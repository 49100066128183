<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Last Will & Testament</h3>

    <ExistingWillYN v-model="form.will_existing"/>

    <transition name="fade">
      <ExistingWillDraftsPerson v-if="willExistingDraftsPerson(form)" v-model="form.will_existing_drafts_person"/>
    </transition>
    <transition name="fade">
      <ExistingWillYear v-if="willExistingYear(form)" v-model="form.will_existing_year"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesRelationshipYN v-if="changesRelationship(form)" v-model="form.changes_relationship"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesRelationshipDetails v-if="changesRelationshipDetails(form)"
                                              v-model="form.changes_relationship_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesChildrenYN v-if="changesDependants(form)" v-model="form.changes_dependants"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesChildrenDetails v-if="changesDependantsDetails(form)"
                                          v-model="form.changes_dependants_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesExecutorsYN v-if="willChangesAppointed(form)" v-model="form.changes_appointed"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesExecutorsDetails v-if="willChangesAppointedDetails(form)"
                                           v-model="form.changes_appointed_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesEstateYN v-if="willChangesEstate(form)" v-model="form.changes_estate"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesEstateDetails v-if="willChangesEstateDetails(form)" v-model="form.changes_estate_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesOtherYN v-if="willChangesOther(form)" v-model="form.changes_will_other"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesOtherDetails v-if="willChangesOtherDetails(form)" v-model="form.changes_will_other_details"/>
    </transition>
    <ExistingWillOverseasYN v-if="willExistingOverseas(form)" v-model="form.will_existing_overseas"/>

    <h3 class="fieldset-heading">Lasting Power of Attorney</h3>

    <ExistingLpaYN v-model="form.lpa_existing"/>
    <transition name="fade">
      <ExistingLpaType v-if="lpaExistingType(form)" v-model="form.lpa_existing_type"/>
    </transition>
    <transition name="fade">
      <ExistingLpaChangesYN v-if="lpaChanges(form)" v-model="form.lpa_changes"/>
    </transition>
    <transition name="fade">
      <ExistingLpaChangesDetails v-if="lpaChangesDetails(form)" v-model="form.lpa_changes_details"/>
    </transition>

    <h3 v-if="funeralPlanHas(form)" class="fieldset-heading">Funeral</h3>

    <ExistingFuneralPlanYN v-if="funeralPlanHas(form)" v-model="form.funeral_plan_has"/>
    <FuneralFundsYN v-if="funeralPlanFunds(form)" v-model="form.funeral_plan_funds"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';

import {showClientQuestionHelpers} from "../../../../../views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import ExistingLpaYN from "../../question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaYN";
import ExistingWillYN from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillYN";
import ExistingWillYear from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillYear";
import ExistingWillDraftsPerson
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillDraftsPerson";
import ExistingWillChangesRelationshipYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesRelationshipYN";
import ExistingWillChangesRelationshipDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesRelationshipDetails";
import ExistingWillChangesChildrenYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesChildrenYN";
import ExistingWillChangesChildrenDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesChildrenDetails";
import ExistingWillChangesExecutorsYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesExecutorsYN";
import ExistingWillChangesExecutorsDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesExecutorsDetails";
import ExistingWillChangesEstateYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesEstateYN";
import ExistingWillChangesEstateDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesEstateDetails";
import ExistingWillChangesOtherYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesOtherYN";
import ExistingWillChangesOtherDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesOtherDetails";
import ExistingLpaChangesYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaChangesYN";
import ExistingLpaChangesDetails
  from "../../question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaChangesDetails";
import ExistingWillOverseasYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillOverseasYN";
import ExistingLpaType from "../../question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaType";
import ExistingFuneralPlanYN
  from "../../question/questions/estatePlanningSurvey/existingProducts/funeral/ExistingFuneralPlanYN";
import FuneralFundsYN from "../../question/questions/estatePlanningSurvey/existingProducts/funeral/FuneralFundsYN";

export default {
  name: 'ExistingProducts',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    FuneralFundsYN,
    ExistingFuneralPlanYN,
    ExistingLpaType,
    ExistingWillOverseasYN,
    ExistingLpaChangesDetails,
    ExistingLpaChangesYN,
    ExistingWillChangesOtherDetails,
    ExistingWillChangesOtherYN,
    ExistingWillChangesEstateDetails,
    ExistingWillChangesEstateYN,
    ExistingWillChangesExecutorsDetails,
    ExistingWillChangesExecutorsYN,
    ExistingWillChangesChildrenDetails,
    ExistingWillChangesChildrenYN,
    ExistingWillChangesRelationshipDetails,
    ExistingWillChangesRelationshipYN,
    ExistingWillDraftsPerson,
    ExistingWillYear,
    ExistingWillYN,
    ExistingLpaYN
  },
  data() {
    return {
      label: 'Existing Products' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
