<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";
import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'WillResidentialChildrenAfterYN',
  mixins: [general, questionLoadHelpers, clientHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    clientData: {
      type: Object,
      required: true
    },
    toPartnerFirst: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    solePropertyOwner() {
      return !!this.clientData.assets.filter(
          (a) =>
              a.assetType ===
              'Residential Home' &&
              a.clients.length === 1
      ).length
    },
    partner() {
      return this.clientData.people.find(
          (person) => this.isPartner(person)
      )
    },
    question() {
      let share = this.solePropertyOwner ? '' : 'share of the'
      let to = 'children'
      if (this.toPartnerFirst && this.partner) {
        to = 'children thereafter'
      } else if (this.partner && this.toPartnerFirst === false) {
        to = 'children'
      }

      return {
        title: `Are you planning on leaving your ${share} residential home to your ${to}?`,
        subTitle: null,
        tip: null,
      }
    }
  },
  data() {
    return {

      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_distribution_residential_children_after',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
