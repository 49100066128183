<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import QuestionBase from "../../../QuestionBase";
import InputTextArea from "../../../inputs/InputTextArea";

export default {
  name: 'WillOtherAssetsDetails',
  mixins: [questionLoadHelpers, showClientQuestionHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    question () {
      return  {
        title:  `Who are you planning on leaving your ${this.willDistributionResidentialPartner(this.form) ? 'other' : ''} assets too?`,
        subTitle:  null,
        tip: null
      }
    }
  },
  data () {
    return {
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_distribution_other_assets_details'
      },
      args: {
        placeholder: null,
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
